// Función para codificar el estado en base64
export const encodeState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    return btoa(serializedState)
  } catch (err) {
    console.error('Error al codificar el estado: ', err)
    return null
  }
}

// Función para decodificar el estado desde base64
export const decodeState = (encodedState) => {
  try {
    const decodedState = atob(encodedState)
    return JSON.parse(decodedState)
  } catch (err) {
    console.error('Error al decodificar el estado: ', err)
    return null
  }
}
