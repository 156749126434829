import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { decodeState } from './security'

// Obtener el estado codificado del local storage y cargarlo en el store
const encodedState = localStorage.getItem('reduxState')
if (encodedState) {
  const persistedState = decodeState(encodedState)
  if (persistedState) store.dispatch({ type: 'set', ...persistedState })
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)

reportWebVitals()
