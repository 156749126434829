//store.ja
import { createStore } from 'redux'
import { encodeState } from './security'

const initialState = {
  sidebarShow: true,
  session: null,
  token: null,
  user: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'user':
      return { ...state, user: rest.payload }
    case 'token':
      return { ...state, token: rest.payload }
    case 'access':
      return { ...state, access: rest.payload }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

const store = createStore(changeState)

// Suscribirse a los cambios del store y guardar el estado en el local storage
store.subscribe(() => {
  const state = store.getState()
  const encodedState = encodeState(state)
  if (encodedState) localStorage.setItem('reduxState', encodedState)
})

export default store
