import React, { Component, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const CustomLayout = React.lazy(() => import('./layout/CustomLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/security/Login'))
const Logout = React.lazy(() => import('./views/pages/security/Logout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const Default = React.lazy(() => import('./views/pages/default/Default'))
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="*" element={<Page404 />} />
            <Route exact path={`/`} element={<Navigate to={`/default`} />} />
            <Route exact path={`/default`} element={<Default />} />
            <Route exact path={`/login`} element={<Login />} />
            <Route exact path={`/logout`} element={<Logout />} />
            <Route exact path={`/register`} element={<Register />} />
            <Route exact path={`/500`} element={<Page500 />} />
            <Route exact path={`/ejemplo/*`} element={<DefaultLayout />} />
            <Route exact path={`/admin/*`} element={<CustomLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
